import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'


//货主重新选择服务商(其实就是用开票单号查询运单)
export const billsInfoDeatils = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/BillsInfoDeatils`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//服务商查询货主是否付款
export const ownerPays = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/WayBill/OwnerPays?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//服务商下载税务表格
export const downTaxation = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/DownTaxation`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//开票完成（服务商修改页调的接口）
export const modifyInvoiceData = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsignTrust/ModifyInvoiceData`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//更新票据状态
export const refuToBill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/RefuToBill`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//组合发票页面导出税务表格
export const downBillsDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/DownBillsDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查询当前组合发票的运费 服务费/税费 运单总金额 发票数量
export const combinaStatis = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/CombinaStatis`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


//服务商放弃开票
export const providerGiveUpInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/ProviderGiveUpInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取发票面额
export const getDenomination = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Invoice/GetDenomination`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//组合发票
export const combinInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/CombinInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//服务商统计运单信息
export const statisWaybill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/StatisWaybill`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//发票ocr识别
export const invoicePhoto = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/InvoicePhoto`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//服务商统计运单信息（带发票图片的）
export const billForDetals = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/BillForDetals`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//开票完成
export const updateInvoiceStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/UpdateInvoiceStatus`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


//服务商查询运单详情（货物承运）
export const getInvoiceDetaleds = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/GetInvoiceDetaleds`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//服务商查询运单详情（交通运输）
export const invoiceTaskDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/InvoiceTaskDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//服务商查询运单详情（土石方）
export const earthWorkGetInvoiceDetals = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EarthWork/EarthWorkGetInvoiceDetals`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//手动组合发票
export const manualCombina = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBill/ManualCombina`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//通过开票单号查发票
export const verifyPrinTaskID = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Invoice/VerifyPrinTaskID?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


//服务商查询分账状态
export const ledgerStatus = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Yumin/Ledger/Status?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//委托代征组合发票
export const commissionCombinInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Trust/CombinInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传发票清单
export const addInvoiceChecklist = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/AddInvoiceChecklist`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//批量上传发票
export const uploadInvoices = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Image/Upload/Invoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传特殊发票
export const specialInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Image/SpecialInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//暂存
export const printDataStore = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/PrintDataStore`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取快捷回复列表
export const getDataDict = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetDataDict?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 一键开票
export const autoInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/HangXin/AutoInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取发票明细
export const GetInvoiceDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/HangXin/GetInvoiceDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发票批处理请求（重开，冲红，下载）
export const BatchInvoiceAction = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/HangXin/BatchInvoiceAction`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发票名查询
export const GetInvoiceName = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/HangXin/GetInvoiceName`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 更新发票显示名称
export const UpdateInvoiceName = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/HangXin/UpdateInvoiceName`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发票预览
export const PreviewInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/HangXin/PreviewInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取发票余量
export const GetInvoiceStore = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/HangXin/GetUKStore`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询发票列表
export const GetInvoiceList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/GetInvoiceList`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 上传发票
export const UploadInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/UploadInvoice`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除发票
export const DelInvoiceInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/DelInvoiceInfo`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 开票完成校验
export const CompleteVerify = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/CompleteVerify`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 上传清单
export const UploadEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/UploadEvidence`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除清单
export const DelEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/DelEvidence`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 更新发票信息
export const EidtInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/EidtInvoice`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
